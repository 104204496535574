// ./frontend/src/agents.js
const agents = {
  helpMe: {
    id: '7cyfwsmqzowgu8nc',
    name: 'helpMe',
    description: 'HelpMe agent providing support information for all internal IT questions, MYOB account issues, and general helpdesk queries. Think of it like a level 1 helpdesk agent.',
    details: ['support', 'help', 'faq', 'IT', 'MYOB', 'helpdesk']
  },
  statusHub: {
    id: 'o5p7bzkatkegwdmu',
    name: 'statusHub',
    description: 'StatusHub agent providing information for all incidents that have been occurring recently. It\'s updated once every hour, and for any specific queries about active incidents or ongoing problems it should be able to find current issues and their status.',
    details: ['incidents', 'outages', 'service disruptions', 'downtime', 'maintenance', 'alerts', 'status updates', 'error reports', 'system health', 'issue tracking']
  },
  securityBot: {
    id: 'b5abhfgwniyrs54u',
    name: 'securityBot',
    description: 'Security Support Bot offering assistance with security protocols, governance, risk management, and cybersecurity compliance.',
    details: ['security', 'grc', 'cybergrc', 'governance', 'risk management', 'cybersecurity']
  },
  customerInterviews: {
    id: 'p2ei3x8rslr8oud5',
    name: 'customerInterviews',
    description: 'Customer Interview Bot assisting with information about customer interviews, including scheduling, conducting, and analyzing customer calls.',
    details: ['interview', 'customer', 'calls', 'scheduling', 'conducting interviews', 'analyzing']
  }
};

module.exports = agents;