import React, { useState, useEffect, useRef } from "react";
import { BiPlus, BiSolidUserCircle, BiSend } from "react-icons/bi";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import agents from "./agents";
import logo from './logo.svg';

const msalConfig = {
  auth: {
    clientId: '32fdb172-51ab-4fcf-8ae7-141f2f8497be',
    authority: 'https://login.microsoftonline.com/ec47f5ff-c7b8-4848-8659-11c2c424d120',
    redirectUri: window.location.href
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};


const msalInstance = new window.msal.PublicClientApplication({
  auth: {
      clientId: '32fdb172-51ab-4fcf-8ae7-141f2f8497be',
      authority: 'https://login.microsoftonline.com/ec47f5ff-c7b8-4848-8659-11c2c424d120',
      redirectUri: window.location.href,
  },
  cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
  },
});


// Use an environment variable for the backend URL or default to localhost
const backendUrl = process.env.REACT_APP_BACKEND_URL || "http://localhost:3000";

const App = () => {
  const [text, setText] = useState("");
  const [pendingAgent, setPendingAgent] = useState(null);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [jwtToken, setJwtToken] = useState(null);
  const [messages, setMessages] = useState({}); // Messages state as an object keyed by messageId
  const [eventSource, setEventSource] = useState(null); // Event source state
  const [chatSessionId, setchatSessionId] = useState(""); // Conversation ID state

  const [isAuthenticated, setIsAuthenticated] = useState(false); // New state for authentication

  const chatEndRef = useRef(null); // Ref to track the end of the chat for scrolling
  const userScrolling = useRef(false); // Ref to track if the user is actively scrolling
  
  const [showAssistanceDialog, setShowAssistanceDialog] = useState(false);
  const [summaryText, setSummaryText] = useState('');
  const [isLoadingSummary, setIsLoadingSummary] = useState(false);

  // Add this function to handle launching Zendesk
  const launchZendesk = (summary) => {
    console.log('Launching Zendesk with summary:', summary);
    //this can only be done when we're embedded in the zendesk page as a chat widget
    //So we need to detect if we're in the zendesk page and if so, launch the chat widget with the summary
    if(window.zE) {
      window.zE('webWidget', 'open');
      window.zE('webWidget', 'updateChat', {
        summary: summary
      });
    } 
  };


  

  
  useEffect(() => {
    // Expose the summarizeChat function globally
    window.summarizeChat = summarizeChat;
  }, [jwtToken]); // Add jwtToken as dependency since summarizeChat uses it
  
  const checkAuth = () => {
    return !!cookieStorage.getItem("authData"); // Simulate auth check
  };

  useEffect(() => {
    const authStatus = checkAuth();
    setIsAuthenticated(authStatus);
  
    if (!authStatus) {
      //debugger;
      window.parent.postMessage({ type: "AUTH_REQUEST" }, "*"); // Notify parent
    }
  }, []);
  
  
  useEffect(() => {
    const mainSection = document.querySelector('.main');
    if (!mainSection) return;

    const handleCitationHover = (e) => {
      if(e.target.tagName === "A") {
        const citationIndex = e.target.innerText;
        if(e.target.classList.contains("global")) {
          //Handle highlighting the relevant links in the entire message
          const message = e.target.closest(".message");
          console.log("message", message);
          //This selector should be able to match data-citationid even when there's multiple citationids in the data attribute...
          const allCitations = message.querySelectorAll('.message-content .citation-range[data-citationid~="' + e.target.dataset.citationid + '"]');
          allCitations.forEach((citation) => {
            citation.classList.toggle("bg-yellow-100", e.type === 'mouseenter');
          });
          return;
        }
        if(isNaN(citationIndex) || citationIndex === "") {
          console.log("citationIndex is not a number or empty string", citationIndex);
          return;
        }
        // Find the closest citation element (either span or li)
        const citationElement = e.target.parentElement.querySelector('.citation-range.citation-' + citationIndex);
        console.log("citationElement", citationElement);
        console.log("citationIndex", citationIndex);
        if (!citationElement) return;
        
        if (e.type === 'mouseenter') {
          citationElement.classList.add('bg-yellow-100');
        } else if (e.type === 'mouseleave') {
          citationElement.classList.remove('bg-yellow-100');
        }
      }
    };

    
    mainSection.addEventListener('mouseenter', handleCitationHover, true);
    mainSection.addEventListener('mouseleave', handleCitationHover, true);

    return () => {
      mainSection.removeEventListener('mouseenter', handleCitationHover, true);
      mainSection.removeEventListener('mouseleave', handleCitationHover, true);
    };
  }, []);

  const cookieStorage = {
    getItem(key) {
      const cookies = document.cookie.split("; ");
      for (const cookie of cookies) {
        const [cookieKey, cookieValue] = cookie.split("=");
        if (cookieKey === encodeURIComponent(key)) {
          return decodeURIComponent(cookieValue);
        }
      }
      return null; // Return null if the key doesn't exist
    },
  
    setItem(key, value, options = {}) {
      const defaults = {
        expires: new Date(Date.now() + 28 * 24 * 60 * 60 * 1000), // Default to 7 days from now
        path: "/", // Default path
        secure: true, // Default to Secure
        sameSite: "None", // Default to None (for cross-origin support)
      };
  
      const { expires, path, domain, secure, sameSite } = { ...defaults, ...options };
      let cookieString = `${encodeURIComponent(key)}=${encodeURIComponent(value)}; path=${path}`;
  
      if (expires) {
        const expiresDate = typeof expires === "number" ? new Date(Date.now() + expires) : expires;
        cookieString += `; expires=${expiresDate.toUTCString()}`;
      }
  
      if (domain) {
        cookieString += `; domain=${domain}`;
      }
  
      if (secure) {
        cookieString += "; secure";
      }
  
      if (sameSite) {
        cookieString += `; samesite=${sameSite}`;
      }
  
      document.cookie = cookieString;
    },
  
    removeItem(key) {
      this.setItem(key, "", { expires: -1 }); // Set an expired date to delete the cookie
    },
  
    clear() {
      const cookies = document.cookie.split("; ");
      for (const cookie of cookies) {
        const [cookieKey] = cookie.split("=");
        this.removeItem(decodeURIComponent(cookieKey));
      }
    },
  };
  

  useEffect(() => {
    /*
    msalInstance.handleRedirectPromise()
      .then((response) => {
        if (response) {
          console.log("JWT Token retrieved: ", response.accessToken);
          setJwtToken(response.accessToken);
        } else {
          const accounts = msalInstance.getAllAccounts();
          if (accounts.length > 0) {
            msalInstance.ssoSilent({
              scopes: ["User.Read"],
              loginHint: accounts[0].username
            }).then((response) => {
              console.log("JWT Token retrieved silently: ", response.accessToken);
              setJwtToken(response.accessToken);
            }).catch((error) => {
              console.error('Silent SSO failed, redirecting to login:', error);
              msalInstance.loginRedirect({
                scopes: ["User.Read"]
              });
            });
          } else {
            msalInstance.loginRedirect({
              scopes: ["User.Read"]
            });
          }
        }
      })
      .catch((error) => {
        console.error('Authentication error:', error);
      });
      */
     //debugger;
      let returnUrl = decodeURIComponent(document.location.href.split("?")[1]);
      //If we are already authenticated, we don't need to do anything else.
      if(isAuthenticated) {
        return;
      }

      //We need to check if we have a valid looking JwtToken and if so, set isAuthenticated to true.
      let authData = cookieStorage.getItem("authData");
      if(authData)  {
        authData = JSON.parse(authData);
      }
      //debugger;
      if(authData && authData.accessToken && authData.expirationTime > Date.now()) {
        setJwtToken(authData.accessToken);
        setIsAuthenticated(true);
        if(document.cookie.includes("authRedirect=")) {
          let authRedirect = cookieStorage.getItem("authRedirect");
          cookieStorage.removeItem("authRedirect");
          window.location.href = authRedirect; // Redirect back to the host page
        }
        return;
      } else {
        //if we're in an iframe, we need to redirect to the parent page to get a token.
        if(window.parent !== window) {
          cookieStorage.setItem("authRedirect", returnUrl);
          //debugger;
          window.parent.postMessage({ type: "AUTH_REQUEST" }, "*"); // Notify parent
          return;
        }
      }

      msalInstance
      .handleRedirectPromise()
      .then((response) => {
        //debugger;
        if (response) {
          // setJwtToken(response.accessToken);
          // setIsAuthenticated(true); // Update auth state
          const tokenData = {
            accessToken: response.accessToken,
            expirationTime: new Date(response.expiresOn).getTime(), // Calculate expiration time
            account: response.account.username, // Save user info
          };
  
          // Store token data in cookieStorage
          cookieStorage.setItem("authData", JSON.stringify(tokenData));
          setJwtToken(response.accessToken); // Set the token in state
          setIsAuthenticated(true); // Update auth state
          //check if we need to redirect back to the host page: 
          if(document.cookie.includes("authRedirect=")) {
            
            //window.parent.postMessage({ type: "AUTH_SUCCESS" }, "*"); // Notify parent
            let authRedirect = cookieStorage.getItem("authRedirect");
            cookieStorage.removeItem("authRedirect");
            window.location.href = authRedirect; // Redirect back to the host page
          }
        } else {
          const accounts = msalInstance.getAllAccounts();
          //debugger;
          if (accounts.length > 0) {
            msalInstance
              .ssoSilent({
                scopes: ["User.Read"],
                loginHint: accounts[0].username,
              })
              .then((response) => {
                //setJwtToken(response.accessToken);
                //setIsAuthenticated(true); // Update auth state
                //check if we need to redirect back to the host page: 
                const tokenData = {
                  accessToken: response.accessToken,
                  expirationTime: new Date(response.expiresOn).getTime(), // Calculate expiration time
                  account: response.account.username, // Save user info
                };
                //debugger;
                // Store token data in cookieStorage
                cookieStorage.setItem("authData", JSON.stringify(tokenData));
                setJwtToken(response.accessToken); // Set the token in state
                setIsAuthenticated(true); // Update auth state

                if(document.cookie.includes("authRedirect=")) {
                  
                  //window.parent.postMessage({ type: "AUTH_SUCCESS" }, "*"); // Notify parent
                  let authRedirect = cookieStorage.getItem("authRedirect");
                  cookieStorage.removeItem("authRedirect");
                  window.location.href = authRedirect; // Redirect back to the host page
                }
              })
              .catch((error) => {
                //Store a cookie so next time we load we know we need to redirect back to the host page:
                cookieStorage.setItem("authRedirect", returnUrl);
                console.error("Silent SSO failed, redirecting to login:", error);
                //debugger;
                window.parent.postMessage({ type: "AUTH_FAILED" }, "*"); // Notify parent
                msalInstance.loginRedirect({
                  scopes: ["User.Read"],
                });
              });
          } else {
            //Store a cookie so next time we load we know we need to redirect back to the host page:
            cookieStorage.setItem("authRedirect", returnUrl);
            //debugger;
            window.parent.postMessage({ type: "AUTH_FAILED" }, "*"); // Notify parent
            msalInstance.loginRedirect({
              scopes: ["User.Read"],
            });
          }
        }
      })
      .catch((error) => {
        //store a cookie so next time we load we know we need to redirect back to the host page:
        cookieStorage.setItem("authRedirect", returnUrl);
        //debugger;
        console.error("Authentication error:", error);
        window.parent.postMessage({ type: "AUTH_ERROR" }, "*");
      });
    
  }, []);

  const handleLowSentiment = async () => {
    setIsLoadingSummary(true);
    try {
      const summary = await summarizeChat();
      setSummaryText(summary.summary);
      setShowAssistanceDialog(true);
    } catch (error) {
      console.error('Error getting summary:', error);
    } finally {
      setIsLoadingSummary(false);
    }
  };


  const submitHandler = async (e) => {
    e.preventDefault();
    if (!text) return;

    if (!jwtToken) {
      setErrorText("User is not authenticated.");
      return;
    }

    setErrorText("");
    setIsLoading(true);


    // Add the user message to the chat
    const messageId = `msg-${Date.now()}`; // Generate a unique messageId
    setMessages((prevMessages) => ({
      ...prevMessages,
      [messageId]: { role: "user", content: text, messageId, chatSessionId: chatSessionId, citations: [] }
    }));

    if (eventSource) {
      console.log("Closing eventSource at initialization");
      eventSource.close();
    }

    try {
      let includeConversation = "";
      if(chatSessionId && chatSessionId !== "") {
        includeConversation = `chatSessionId=${chatSessionId}&`;
      }
      const newEventSource = new EventSource(`${backendUrl}/chat?${includeConversation}message=${encodeURIComponent(text)}&token=${encodeURIComponent(jwtToken)}&messageId=${messageId}`, {withCredentials: true});
      setEventSource(newEventSource);
      window.messageText = "";
      newEventSource.addEventListener("message", (event) => {
        setIsLoading(false);
        let parsedData;
        try {
          parsedData = JSON.parse(event.data);
            // Check for low sentiment score
            if (parsedData.sentiment_score && parsedData.sentiment_score < 34) {
              // Trigger summary and show dialog
              handleLowSentiment();
            }
          console.log("Received data chunk:", parsedData);
        } catch (error) {
          console.error('Error parsing chunk from backend:', error);
          return;
        }

        let doNotAppendToChat = false;

        // Handle special agent/system messages
        if (/^\*\*[\w]+:\*\*/.test(parsedData.text)) {
          setErrorText(parsedData.text.replace(/\*\*([\w]+):\*\*/, '$1...'));
          doNotAppendToChat = true;
        }

        if (parsedData.agentEnd) {
          setErrorText("");
        }

        if (parsedData.chatSessionId) {
          setchatSessionId(parsedData.chatSessionId);
        }

        // Handle message accumulation
        if (parsedData.text && parsedData.text.includes("Generating your final response now.")) {
          console.log("Finished preamble, begin appending to chat");
          setMessages((prevMessages) => {
            console.log("prevMessages:", prevMessages, "length:", Object.keys(prevMessages).length);
            return prevMessages;
          });
        } else if (!doNotAppendToChat && parsedData.messageId) {
          setMessages((prevMessages) => {
            const existingMessage = prevMessages[parsedData.messageId] || {};
            let updatedContent = (existingMessage.content || "") + (parsedData.text || "");
            let updatedCitations = existingMessage.citations ? [...existingMessage.citations] : [];
            let textCitations = [];
            
            if (parsedData.citations && parsedData.citations.length > 0) {
              parsedData.citations.forEach((citation) => {
                const { sourceDocument, referenceRanges } = citation;
                if (referenceRanges && sourceDocument) {
                  // Add citation to the citations list only once per sourceDocument
                  if (!updatedCitations.some((cit) => cit.id === sourceDocument.id)) {
                    updatedCitations.push({
                      id: sourceDocument.id,
                      index: updatedCitations.length + 1,
                      title: sourceDocument.title,
                      url: sourceDocument.url
                    });
                  }

                  referenceRanges.forEach((reference) => {
                    const { textRange } = reference;
                    if (textRange) {
                      const citationIndex = updatedCitations.findIndex((cit) => cit.id === sourceDocument.id) + 1;
                      const citationText = `</span><a class="citation-link" data-citationid="${sourceDocument.id}" href="${sourceDocument.url}" title="${sourceDocument.title}">${citationIndex}</a>`;
                      textCitations.push({
                        insertionIndex: textRange.endIndex,
                        citationText,
                        citationIndex
                      });
                      const startCitationText = `<span class="citation-range citation-${citationIndex}" data-citationid="${sourceDocument.id}">`;
                      textCitations.push({
                        insertionIndex: textRange.startIndex,
                        citationText: startCitationText,
                        citationIndex
                      });
                    }
                  });
                }
              });

              // Sort textCitations in descending order of insertionIndex
              textCitations.sort((a, b) => (b.insertionIndex+b.citationIndex) - (a.insertionIndex+a.citationIndex));

              // Insert citation texts at the desired indices
              textCitations.forEach(({ insertionIndex, citationText }) => {
                let adjustedIndex = insertionIndex;

                // Check if there's enough room and if the content at insertionIndex starts with </span>
                if (adjustedIndex + 7 <= updatedContent.length && updatedContent.slice(adjustedIndex, adjustedIndex + 7) === "</span>") {
                  // Move the insertion point to after this </span>
                  adjustedIndex += 7;
                }

                // Insert citation text at the adjusted index
                updatedContent =
                  updatedContent.slice(0, adjustedIndex) +
                  citationText +
                  updatedContent.slice(adjustedIndex);
              });

              console.log(updatedContent);
            }

            // Post-process updatedContent to merge redundant nested spans
            const mergeRedundantSpans = (content) => {
              // Regex pattern to match nested <span> tags
              const spanRegex = /<span([^>]*)>\s*<span([^>]*)>(.*?)<\/span>\s*<\/span>/g;
            
              // Loop to collapse nested spans until no more matches are found
              while (spanRegex.test(content)) {
                content = content.replace(spanRegex, (match, outerAttrs, innerAttrs, innerContent) => {
                  // Parse and merge attributes intelligently
                  const mergedAttrs = mergeAttributes(outerAttrs, innerAttrs);
                  return `<span ${mergedAttrs}>${innerContent}</span>`;
                });
              }
            
              return content;
            };
            
            // Helper function to parse attributes and merge them intelligently
            const mergeAttributes = (outerAttrs, innerAttrs) => {
              const parseAttributes = (attrs) => {
                const attrRegex = /(\w+(?:-\w+)*)="([^"]*)"/g;
                const attributes = {};
                let match;
            
                while ((match = attrRegex.exec(attrs))) {
                  const [_, key, value] = match;
                  attributes[key] = value;
                }
            
                return attributes;
              };
            
              // Parse both sets of attributes into key-value pairs
              const outer = parseAttributes(outerAttrs);
              const inner = parseAttributes(innerAttrs);
            
              // Combine attributes, prioritizing inner values where necessary
              const combined = { ...outer, ...inner };
            
              // Merge and deduplicate `class` attributes
              if (outer.class && inner.class) {
                const uniqueClasses = new Set([...outer.class.split(" "), ...inner.class.split(" ")]);
                combined.class = Array.from(uniqueClasses).join(" ");
              }
            
              // Merge and deduplicate `data-*` attributes
              Object.keys(outer).forEach((key) => {
                if (key.startsWith("data-") && inner[key]) {
                  const uniqueDataValues = new Set([...outer[key].split(","), ...inner[key].split(",")]);
                  combined[key] = Array.from(uniqueDataValues).join(" ");
                }
              });
            
              // Reconstruct attribute string
              return Object.entries(combined)
                .map(([key, value]) => `${key}="${value}"`)
                .join(" ");
            };
            
            

            // Apply post-processing to updatedContent
            updatedContent = mergeRedundantSpans(updatedContent);


            if(parsedData.agent.toLowerCase() !== "system" && parsedData.agent && !currentAgent) {
              setCurrentAgent(parsedData.agent);
              //clear the current chat session id because we're supposed to be talking to a new app now.
              setchatSessionId(null);
            }

            return {
              ...prevMessages,
              [parsedData.messageId]: {
                ...existingMessage,
                role: existingMessage.role || "agent",
                content: updatedContent,
                agent: existingMessage.agent || agents[parsedData.agent]?.name || "Unknown",
                messageId: parsedData.messageId,
                chatSessionId: parsedData.chatSessionId,
                citations: updatedCitations
              }
            };
          });
        }
      });

      newEventSource.onerror = (error) => {
        setIsLoading(false);
        console.error('Error in submitHandler:', error);
        setErrorText('An error occurred while receiving the response.');
        setPendingAgent(null);
        if (newEventSource) {
          console.log("Closing eventSource due to error");
          newEventSource.close();
        }
      };

    } catch (error) {
      console.error('Error sending message:', error);
      setErrorText('An error occurred while sending the message.');
    } finally {
      setIsLoading(false);
    }

    setText("");
  };

  // Log updates to the messages state and scroll to the bottom if the user is not actively scrolling
  useEffect(() => {
    console.log("Messages updated:", messages);
    if (!userScrolling.current) {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // Track user scrolling
  const handleScroll = (e) => {
    const isAtBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    userScrolling.current = !isAtBottom;
  };

const summarizeChat = async () => {
  if (!jwtToken) {
    setErrorText("User is not authenticated.");
    return;
  }

  const chatHistory = Object.values(messages).map((msg) => ({
    role: msg.role,
    content: msg.content,
    agent: msg.agent,
    messageId: msg.messageId,
    chatSessionId: msg.chatSessionId,
    citations: msg.citations
  }));

  const systemMessage = {
    role: "system",
    content: "Please summarize the following chat history to provide a detailed consumable summary for a support team member."
  };

  const payload = {
    messages: [systemMessage, ...chatHistory]
  };

  try {
    const response = await fetch(`${backendUrl}/summarize`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`,
        'Accept': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      throw new Error('Failed to summarize chat');
    }

    const summary = await response.json();
    console.log('Chat summary:', summary);
    setErrorText("Chat summary sent to support.");

    return summary;
  } catch (error) {
    console.error('Error summarizing chat:', error);
    setErrorText('An error occurred while summarizing the chat.');
  }
};

  const currentChat = Object.values(messages);
  if (!isAuthenticated) {
    return <div>Loading authentication...</div>; // Redirect if unauthenticated
  }
  
  return (
    <div className="container">

      <section className="main" onScroll={handleScroll}>
        <div className="main-header">
            {currentChat.map((chatMsg, idx) => (
              <div className="message" data-chatid={chatMsg.chatSessionId} data-author={chatMsg.role} key={chatMsg.messageId || idx} data-messageid={chatMsg.messageId}> {/* Using messageId if available */}
                <div className="author">
                  {chatMsg.role === "user" ? (
                  <div>
                    <BiSolidUserCircle size={28.8} />
                  </div>
                ) : (
                  <img src={logo} alt="Agent Message" />
                )}
                <p className="role-title">{chatMsg.role === "user" ? "You" : `Agent (${chatMsg.agent})`}</p>
                </div>
                <div>
                  <div className="message-content"><ReactMarkdown rehypePlugins={[rehypeRaw]}>{chatMsg.content}</ReactMarkdown></div>
                </div>
                {/* {chatMsg.role !== "user" && chatMsg.chatSessionId && (
                  <button onClick={() => setchatSessionId(chatMsg.chatSessionId)} className="resume-session-button">
                    Reply
                  </button>
                )} */}
                {chatMsg.citations && chatMsg.citations.length > 0 && (
                  <div className="citations-container">
                    <ul>
                      {chatMsg.citations.map((citation) => (
                        <li key={citation.index} className="citation-item">
                          {citation.index}. <a className="citation-link global" data-citationid={citation.id} href={citation.url} target="_blank" rel="noopener noreferrer">{citation.title}</a>{" "}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          <div ref={chatEndRef} />
        </div>
        <div className="main-bottom">
          {errorText && <p className="errorText">{errorText}</p>}
          {isLoading && <p className="loadingText">Loading...</p>}
          <form className="form-container" onSubmit={submitHandler}>
            <input
              type="text"
              placeholder="Send a message."
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <button type="submit">
              <BiSend size={20} />
            </button>
          </form>
        </div>
      </section>
      {showAssistanceDialog && (
        <div className="assistance-dialog-overlay">
          <div className="assistance-dialog">
            <h2>It looks like you may need additional assistance</h2>
            <p>
              We'd love to help you out. Please review the content below which we can send to a 
              support team member and start a live chat for you.
            </p>
            <div className="summary-section">
              <textarea
                value={summaryText}
                onChange={(e) => setSummaryText(e.target.value)}
                rows={6}
                placeholder="Loading summary..."
                disabled={isLoadingSummary}
              />
            </div>
            <div className="dialog-buttons">
              <button 
                onClick={() => setShowAssistanceDialog(false)}
                className="cancel-button"
              >
                Cancel
              </button>
              <button 
                onClick={() => {
                  launchZendesk(summaryText);
                  setShowAssistanceDialog(false);
                }}
                className="start-chat-button"
                disabled={isLoadingSummary}
              >
                Start Chat
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;